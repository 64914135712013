<template>
  <div>
    <div v-if="row">
      <a
        v-on:click="reserveOnDesktopClub(row.title)"
        class="btn d-none d-sm-inline"
        style="
                    font-weight: bolder;
                    color: black !important;
                    background-color: aquamarine !important;
                    border: 1px solid aquamarine;
                  "
      >
        BOOK NOW
      </a>
      <!--      SMS-->
      <a
        v-on:click="reserveOnMobileClub(row.metadata.code)"
        class="btn d-inline-xs d-sm-none"
        style="
                    font-weight: bolder;
                    color: black !important;
                    background-color: aquamarine !important;
                    border: 1px solid aquamarine;
                  "
      >BOOK NOW</a
      >
    </div>

    <div v-if="!row">
      <a
        class="btn font-weight-bold d-none d-sm-inline"
        v-on:click="reserveOnDesktopGeneric"
        style="
            color: black !important;
            background-color: aquamarine !important;
            border: 1px solid silver;
          "
      >
        📲 INFO & RESERVATIONS </a>
      <a
        class="btn font-weight-bold d-inline-xs d-sm-none"
        v-on:click="reserveOnMobileGeneric"
        style="
            color: black !important;
            background-color: aquamarine !important;
            border: 1px solid silver;
                        font-size: 0.7rem !important;

          "
      >
        📲 INFO & RESERVATIONS </a>
    </div>
  </div>

</template>
<script>
import Swal from "sweetalert2";
// import 'sweetalert2/dist/sweetalert2.min.css';

export default {
  name: 'RsvpBeachClubsViaDialog',
  props: ['row'],
  methods: {
    replaceTag(string, club) {
      return string.replace('{{club}}', club);
    },

    reserveOnDesktopGeneric() {
      this.rsvpDesktopDialogGeneric();
      this.onFreeConciergeClick('desktop');
    },
    reserveOnDesktopClub(club) {
      this.rsvpDesktopDialogClub(club);
      this.onFreeConciergeClick('desktop');
    },
    reserveOnMobileClub(club) {
      this.rsvpMobileDialogForClub(club);
      this.onFreeConciergeClick('mobile');
    },
    reserveOnMobileGeneric() {
      this.rsvpMobileDialogGeneric();
      this.onFreeConciergeClick('mobile');
    },
    onFreeConciergeClick(channel) {
      window.ga('send', 'event', 'reservations', 'Beach Clubs: Info & Reservations', 'Main', 50);
      this.$rollbar.info('Beach Clubs: Info & Reservations:' + channel);
    },

    rsvpMobileDialogForClub(club) {
      let message = this.replaceTag(this.SMS_MESSAGE_CLUB_SPECIFIC, club.toUpperCase());
      let options = Object.assign({}, this.BASE_MODAL, {

        html: `<h5 class="tulum-party-title" style="color:aquamarine;text-shadow: black 1px 1px 1px;text-transform: capitalize">${club}</h5>`,
        footer: this.mailFooter(),
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          open('https://wa.me/524421867547?text=' + message);
        } else if (result.isDenied) {
          open('sms:+524421867547&body=' + message);
        }
      });
    },

    rsvpMobileDialogGeneric() {
      let options = Object.assign({}, this.BASE_MODAL, {
        footer: this.mailFooter(),
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          open('https://wa.me/+524421867547?text=' + this.SMS_MESSAGE_GENERIC);
        } else if (result.isDenied) {
          open('sms:+524421867547&body=' + this.SMS_MESSAGE_GENERIC);
        }
      });
    },

    rsvpDesktopDialogClub(club) {
      let options = Object.assign({}, this.BASE_MODAL, {
        html: `<h5 class="tulum-party-title" style="color:aquamarine;text-shadow: black 1px 1px 1px;">${club}</h5>`,
        footer: this.mailFooter(),
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          this.rsvpDesktopWhatsQR(club);
        } else if (result.isDenied) {
          this.rsvpDesktopSmsQR(club);
        }
      });
    },
    rsvpDesktopDialogGeneric() {
      let options = Object.assign({}, this.BASE_MODAL, {
        footer: this.mailFooter(),
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          this.rsvpDesktopWhatsQR();
        } else if (result.isDenied) {
          this.rsvpDesktopSmsQR();
        }
      });
    },
    rsvpDesktopShowQR(club, qrImageUrl) {
      // <iframe src="https://embed.lottiefiles.com/animation/86701"></iframe>
      let options = Object.assign({}, this.BASE_MODAL, {
        html: `<h4 class="tulum-party" style="text-shadow: #23bac4 1px   4px 11px;">TULUM.PARTY</h4>
        <hr><p style="color:#efefef !important;">Scan the QR and send us a message with your inquiry. We'll be glad to help.</p>
        `,
        title: '',
        imageWidth: 270,
        imageHeight: 270,
        showDenyButton: false,
        showConfirmButton: false,
        footer:
          '<small style="color:#efefef !important;"> We operate from 9AM - 10PM / Answer within 15 mins</small>',
        imageUrl: qrImageUrl,
        imageAlt: 'QR for Contact',
      });
      Swal.fire(options).then((result) => {
      });
    },
    rsvpDesktopWhatsQR(club) {
      this.rsvpDesktopShowQR(
        club,
        'https://imgix.cosmicjs.com/c3b60240-f712-11ec-bf2b-e93971fa55b1-image.png'
      );
    },
    rsvpDesktopSmsQR(club) {
      this.rsvpDesktopShowQR(
        club,
        'https://imgix.cosmicjs.com/140a0b30-f22f-11ec-a2eb-c1653f3f9199-telegram-cloud-photo-size-1-5014946017338042974-x.jpg'
      );
    },
    PERSUASIVE_TEXT_GENERIC() {
      Array.prototype.random = function () {
        return this[Math.floor(Math.random() * this.length)];
      };
      return this.PERSUASIVE_TEXT.random();
    },
    mailFooter() {
      return `<div class="text-center">
          <br>
          <a class="btn btn-sm btn-primary text-center" href="mailto:bookings@tulum.party?subject=reservations">via email</a>
          <br>
          <hr>
          <p style="color:#efefef !important;">
              ${this.PERSUASIVE_TEXT_GENERIC()}
          </p>
        </div>`;
    },
  },
  data: function () {
    const TITLE =
      '<img src="https://imgix.cosmicjs.com/63c3dfe0-9b0f-11ee-b34c-99d1d89e42eb-image.png" height="80px">';
    const SUBTITLE = '<h3 class="tulum-party">Tulum Beach Clubs</h3>';

    return {
      BASE_MODAL: {
        backdrop: true,
        html: `${SUBTITLE}`,
        title: TITLE,
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'WhatsApp',
        denyButtonText: 'SMS',
        imageWidth: 250,
        imageHeight: 250,
        confirmButtonColor: 'black',
        denyButtonColor: 'black',
        imageAlt: 'Custom image',
        background: 'linear-gradient(-45deg, #23bac4, #23d5ab)',
        onBeforeOpen: () => {

        },
      },
      PERSUASIVE_TEXT: [
        'Any questions? Get recommendations and support from our Concierge team.',
        'No extra fees, no hidden costs to weigh you down. Book through us!',
        'No Extra Fees, Just Extra Fun - Book Now!',
        'Save time searching online. Contact our party agents for more info or make a reservation (All top beach clubs in Tulum)',
      ],
      SMS_MESSAGE_GENERIC: 'Hey there, how can we help you today?',
      SMS_MESSAGE_CLUB_SPECIFIC:
        'CLUB_RSVP:%20{{club}}%0a--------%0aFULL_NAME:%20%0aDATE:%20%0aARRIVAL_TIME:%20%0aGROUP_SIZE:%20',
    };
  },
};
</script>
<style scoped>
p,
h1,
h2,
h3,
h4 {
  color: #efefef !important;
}

button {
  color: black !important;
}
</style>
